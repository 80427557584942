<template>
  <div class="app">
    <main>
      <img src="../../assets/img/stellarauth.svg">
      <h1>StellarAuth</h1>

      <div class="actions">
        <button class="sign-up" @click="$emit('setLaunchTemplate', 'sign-up')">Sign Up</button>
        <button class="sign-in" @click="$emit('setLaunchTemplate', 'scan-account-qr-code')">Sign In</button>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

main {
  padding: 60px 0 120px;
  height: 100%;
}

img {
  width: 100px;
}
h1 {
  line-height: 1.5;
  font-size: 32px;
}

.actions {
  margin-top: auto;
  
  .sign-up {
    @extend %button-primary;
    margin-bottom: 30px;
  }
  .sign-in {
    @extend %button-outline;
  }
}
</style>