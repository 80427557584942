<template>
  <div class="app">
    <header>
      <button class="back" @click="goBack"><img src="../../assets/img/back.svg"></button>
      <img class="logo" src="../../assets/img/stellarauth.svg">
      <h1>StellarAuth</h1>
    </header>

    <main>
      <h2>Save or Scan Account QR Code</h2>
      <p>If you have another device you’d like to add, this is how you’ll do it. Feel free to save it or scan it into another device. You can always grab it later from this device.</p>

      <div class="qrcode" :class="{fullsize: qr_fullsize}" :style="{'background-image': `url(${qr_url})`}" @click="qr_fullsize = !qr_fullsize">
        <img class="zoom" src="../../assets/img/zoom.svg">
      </div>

      <div class="actions">
        <a class="save" :href="qr_url" target="_blank">Save</a>
        <button class="next" @click="$emit('setAccountTemplate', 'fresh')" v-if="keys === null">Next</button>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import config from '../../assets/js/config'

export default {
  data() {
    return {
      qr_fullsize: false
    }
  },
  mounted() {
    
  },
  computed: {
    ...mapState([
      'keys',
      'cipher'
    ]),
    qr_url() {
      return `${config.authUrl}/img?cipher=${this.cipher}`
    }
  },
  methods: {
    ...mapMutations([
      'setPassword',
      'setCipher'
    ]),
    ...mapActions([
      'signOut'
    ]),
    goBack() {
      if (this.keys === null) {
        this.signOut()
        this.$emit('setLaunchTemplate', 'sign-up')
      } else
        this.$emit('setAccountTemplate', 'settings')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

.qrcode {
  margin: 40px 0;
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  background-color: #4A90E2;
  background-blend-mode: multiply;
  background-size: contain;

  .zoom {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
  }

  &.fullsize {
    width: calc(400px - 60px);
    height: calc(400px - 60px);
    background-color: $ui-9;
    image-rendering: optimizeSpeed;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;

    .zoom {
      display: none;
    }
  }
}

.actions {

  .save {
    @extend %button-primary;
    margin-bottom: 20px;
  }
  .next {
    @extend %button-outline;
  }
}
</style>