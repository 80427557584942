import { Networks, Transaction } from 'stellar-sdk'
import config from '../assets/js/config'

export default {
  setKeys(state, keys) {
    state.keys = keys && keys.length ? keys : 0
  },
  setTxns(state, txns) {
    state.txns = txns && txns.length ? _.map(txns, (txn) => {
      const details = new Transaction(txn.xdr, Networks[config.network])
      const memo = details.memo._value ? details.memo._value.toString() : null
      delete details.tx
      delete details._memo

      txn.details = Object.assign({}, {
        memo,
        timeBounds: {},
        show: false
      }, details)

      return txn
    }) : 0
  },
  setInfo(state, info) {
    state.info = info
  },
  setUser(state, user = null) {
    state.user = user
  },
  setPassword(state, password = null) {
    state.password = password

    if (password)
      sessionStorage.setItem('StellarAuth.Password', password)
    else
      sessionStorage.removeItem('StellarAuth.Password')
  },
  setCipher(state, cipher = null) {
    state.cipher = cipher

    if (cipher)
      localStorage.setItem('StellarAuth.Cipher', cipher)
    else
      localStorage.removeItem('StellarAuth.Cipher')
  },
  setChannel(state, channel) {
    state.channel = channel
  }
}