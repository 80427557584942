<template>
  <div class="app">
    <header>
      <button class="back" @click="$emit('setAccountTemplate', 'main')"><img src="../../assets/img/back.svg">Back</button>
      <h1>Settings</h1>
    </header>

    <main>
      <ul class="lists">
        <li>
          <ul class="list">
            <label>Authenticated Keys</label>

            <ul>
              <li class="item">
                {{keys ? keys.length : '0'}} Key{{keys.length === 1 ? null : 's'}} 
                <img src="../../assets/img/forward.svg">
              </li>
            </ul>
          </ul>

          <ul class="list">
            <label>QR Code</label>

            <ul>
              <li class="item" @click="$emit('setAccountTemplate', 'view-account-qr-code')">
                Account QR Code
                <img src="../../assets/img/forward.svg">
              </li>
            </ul>
          </ul>
        </li>
      </ul>

      <div class="actions">
        <button class="sign-out" @click="signOut">Sign Out</button>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'keys'
    ]),
  },
  methods: {
    ...mapActions([
      'signOut'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

.app {
  padding: 0;
}

header {
  background-color: $ui-2;
  margin: 0;
  height: 60px;
  padding: 0 15px;

  button {
    font-size: 12px;
    opacity: 0.5;

    img {
      margin-right: 10px;
    }
  }
  h1 {
    margin: 0 auto;
    font-size: 20px;
  }
}

main {
  height: calc(100% - 60px);
  padding: 0;
}
.lists {
  margin-top: 40px;
  padding: 0 15px;
  margin-bottom: 40px;
}
.list {
  margin-bottom: 30px;
}
.item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 15px;
  background-color: $ui-2;
  border-radius: 10px;
  cursor: pointer;

  img {
    width: auto;
    opacity: 0.5;
  }
}
ul {
  width: 100%;
}
label {
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 10px;
  display: block;
}

.actions {
  border-top: 1px solid $ui-2;
  padding: 40px 0;
  margin-top: auto;
  
  .sign-out {
    @extend %button-outline;
  }
}
</style>