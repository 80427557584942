<template>
  <div class="app" :class="{
    mobile: info && (info.platform === 'ios' || info.platform ==='android'),
    loading: appLoading
  }">
    <header>
      <button @click="$emit('setAccountTemplate', 'settings')">
        <img class="settings" src="../../assets/img/settings.svg">
      </button>
      <button @click="_refetchData">
        <svg width="100" height="113" viewBox="0 0 100 113" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'logo'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g class="group" fill="#F2F2F2" fill-rule="nonzero"><path class="top" d="M25 45l-6.187-16.926L0 22.504l18.813-5.569L25 0l6.187 16.935L50 22.505l-18.813 5.57z"/><path class="right" d="M75 67.5l-6.187-16.926L50 45.004l18.813-5.569L75 22.5l6.187 16.935L100 45.005l-18.813 5.57z"/><path class="bottom" d="M75 112.5l-6.187-16.926L50 90.004l18.813-5.569L75 67.5l6.187 16.935L100 90.005l-18.813 5.57z"/><path class="left" d="M25 90l-6.187-16.926L0 67.504l18.813-5.569L25 45l6.187 16.935L50 67.505l-18.813 5.57z"/></g></svg>
      </button>
      <button @click="$emit('setAccountTemplate', 'scan-key-qr-code')">
        <img class="add" src="../../assets/img/add.svg">
      </button>
    </header>

    <div 
      class="ptr"
      v-if="
        info 
        && (
          info.platform === 'ios' 
          || info.platform ==='android'
        )">
      <i>↓</i>
    </div>
  
    <main :style="{overflow: panMoving ? 'hidden' : 'auto'}">
      <ul class="keys">
        <li class="key" v-for="key in filteredKeys" :key="key._key">
          <div class="key-describe">
            <img :src="key.image" v-if="key.image">

            <p :data-key="key._key">{{key.name || key._key}}</p>

            <span :class="{
              red: !key.verified,
              green: key.verified
            }">{{key.verified ? 'Verified' : 'Unverified'}}</span>
          </div>

          <hr class="sticky">
          
          <ul class="txns" v-show="txns">
            <hr>

            <template v-for="(txn) in filterTxns(key._key)">
              <li 
                class="txn"
                v-hammer:panstart="txn.status === 'sent' ? (event) => panStart(event, txn) : panNoop"
                v-hammer:pan="txn.status === 'sent' ? (event) => pan(event, txn) : panNoop"
                v-hammer:panend="txn.status === 'sent' ? (event) => panEnd(event, key, txn) : panNoop"
                :ref="txn._txn"
                :key="`txn-${txn._txn}`"
              >
                <div class="txn-content">
                  <div class="txn-describe">
                    <p>
                      <vue-loader v-show="txn.loading" />
                      <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'signed'" v-show="txn.status === 'signed' || txn.status === 'submitted'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.496.209L7.844 10.833a.513.513 0 01-.379.146.519.519 0 01-.375-.151L3.507 7.246.242 10.51l7.242 7.242 14.29-14.264-3.278-3.28z" fill="#12161F" fill-rule="nonzero"/></svg>
                      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'rejected'" v-show="txn.status === 'rejected'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.263.999l-.708-.707a1 1 0 00-1.414 0L8.778 6.656 2.414.292A1 1 0 001 .292L.293.999a1 1 0 000 1.415l6.364 6.364-6.364 6.363a.999.999 0 000 1.414l.707.707a.999.999 0 001.414 0l6.364-6.363 6.363 6.363a.999.999 0 001.414 0l.708-.707a1 1 0 000-1.414l-6.364-6.363 6.364-6.364c.39-.391.39-1.025 0-1.415z" fill="#12161F" fill-rule="nonzero"/></svg>
                      {{txn.details.memo}}
                    </p>
                    <span>{{txn.status}}</span>
                  </div>

                  <time :datetime="txn.requestedat">
                    <img src="../../assets/img/clock.svg">
                    {{txn.requestedat | timeAgo}}
                  </time>
                  
                  <ul class="ops" v-show="txn.details.show">
                    <li class="op" v-for="(op, i) in txn.details.operations" :key="i">{{op.type}}</li>
                  </ul>
                </div>

                <div class="txn-action"></div>
              </li>

              <hr :key="`hr-${txn._txn}`">
            </template>
          </ul>
        </li>

        <li v-if="keys === 0" class="key empty">No keys found</li>
        <li v-if="txns === 0" class="key empty">No transactions found</li>
      </ul>
    </main>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import Velocity from 'velocity-animate'

import $vueLoader from '../misc/loader'

Velocity.defaults.mobileHA = false
Velocity.defaults.duration = 150
Velocity.defaults.easing = 'linear'

export default {
  data() {
    return {
      appLoading: false,
      txnLoading: false,

      ptrLoading: false,
      ptrScroll: 0,

      panMoving: false,
      panDisabled: false,
      panTimeout: null,

      headerInterval: null
    }
  },
  created() {
    
  },
  mounted() {
    this.getInfo()
    
    if (
      this.keys === null 
      && this.txns === null
    ) {
      this.appLoading = true
      this._refetchData()
    }
  },
  components: {
    'vue-loader': $vueLoader,
  },
  computed: {
    ...mapState([
      'info',
      'keys',
      'txns'
    ]),
    filteredKeys() {
      return _
      .chain(this.keys)
      .sortBy((key) => -_
        .chain(this.filterTxns(key._key))
        .map((txn) => parseInt(txn.requestedat, 10))
        .max()
        .value()
      )
      .value() 
    },
    headerLoading() {
      return this.appLoading || this.txnLoading || this.ptrLoading
    }
  },
  watch: {
    info() {
      if (
        this.info.platform === 'ios'
        || this.info.platform === 'android'
      ) {
        let ptrScroll
        let ptrTimeout
        let ptrMoving

        const $keyGroup = document.querySelector('.keys')
        const $ptrGroup = document.querySelector('.ptr')

        document.querySelector('main').addEventListener('touchstart', () => ptrMoving = !this.ptrLoading)
        document.querySelector('main').addEventListener('touchend', () => ptrMoving = false)

        document.querySelector('main').addEventListener('scroll', () => {
          ptrScroll = Math.max($keyGroup.getBoundingClientRect().top - 80, 0)

          if (ptrScroll === 0)
            return
        
          if (ptrTimeout)
            cancelAnimationFrame(ptrTimeout)

          this.ptrScroll = ptrScroll

          // ptrTimeout = requestAnimationFrame(() => $ptrGroup.style.height = `${ptrScroll}px`)

          if (
            !this.ptrLoading
            && ptrScroll >= 60
          ) {
            this.ptrLoading = true
            // Velocity($ptrGroup.querySelector('i'), {rotateZ: 180}, {duration: 200})
          }
        })

        document.querySelector('main').addEventListener('scroll',  _.debounce(() => {
          if (
            !ptrMoving 
            && ptrScroll === 0
            && this.ptrLoading
          ) {
            this.ptrLoading = false
            Velocity($ptrGroup.querySelector('i'), {rotateZ: 0}, {duration: 200})
            this._refetchData()
          }

          this.ptrScroll = 0
        }, 100))
      }
    },
    headerLoading() {
      const $allPaths = document.querySelectorAll('.logo path')

      if (this.headerLoading) {
        let $previousPath = $allPaths[2]
        let $currentPath = $allPaths[0]
        
        _.defer(next)
        this.headerInterval = setInterval(next, 100)

        function next() {
          $previousPath.classList.remove('hide')
          $currentPath.classList.add('hide')

          if ($currentPath.nextSibling)
            $currentPath = $currentPath.nextSibling
          else
            $currentPath = $allPaths[0]

          if ($currentPath.previousSibling) {
            if ($currentPath.previousSibling.previousSibling)
              $previousPath = $currentPath.previousSibling.previousSibling
            else 
              $previousPath = $allPaths[3]
          } 
          else
            $previousPath = $allPaths[2]
        }
      }

      else {
        clearInterval(this.headerInterval)
        _.each($allPaths, (path) => path.classList.remove('hide'))
      }
    }
  },
  filters: {
    timeAgo(requestedat) {
      const diff = moment().diff(parseInt(requestedat, 10), 'milliseconds')
      return `${moment.duration(diff, 'milliseconds').humanize()} ago`
    }
  },
  methods: {
    ...mapActions([
      'getInfo',
      'txnSign',
      'txnReject',
      'refetchData'
    ]),

    filterTxns(key) {
      return _
      .chain(this.txns)
      .filter({_key: key})
      .orderBy('requestedat', 'desc')
      .value()
    },

    _txnSign(key, txn) {
      if (confirm('You\'re about to accept this transaction.')) {
        this.$set(txn, 'loading', true)
        this.txnLoading = true

        this
        .txnSign({
          xdr: txn.xdr,
          cipher: key.cipher
        })
        .finally(() => this._refetchData())
        .finally(() => txn.loading = false)
      }
    },
    _txnReject(txn) {
      if (confirm('You\'re about to reject this transaction.')) {
        this.$set(txn, 'loading', true)
        this.txnLoading = true

        this
        .txnReject(txn._txn)
        .finally(() => this._refetchData())
        .finally(() => txn.loading = false)
      }
    },
    _refetchData() {
      this.txnLoading = true

      return this.refetchData()
      .finally(() => _.defer(() => {
        this.appLoading = false
        this.txnLoading = false
      }))
    },

    panStart(e, txn) {
      if (
        this.headerLoading
        || this.ptrScroll > 0
        || Math.abs(e.overallVelocityY) >= Math.abs(e.overallVelocityX)
      ) return this.panDisabled = true

      txn.target = this.$refs[txn._txn][0]
      this.panMoving = true
    },
    pan(e, txn) {
      if (this.panTimeout)
        cancelAnimationFrame(this.panTimeout)

      if (
        !txn.target
        || this.panDisabled
        || this.headerLoading
        || this.ptrScroll > 0
      ) return

      this.panTimeout = requestAnimationFrame(() => {
        txn.target.style.transform = `translate3d(${e.deltaX}px, 0, 0)`
      })

      if (e.deltaX >= 0) {
        txn.target.classList.add('left')
        txn.target.classList.remove('right')
        txn.target.previousSibling.classList.add('left')
        txn.target.previousSibling.classList.remove('right')
      } else {
        txn.target.classList.add('right')
        txn.target.classList.remove('left')
        txn.target.previousSibling.classList.add('right')
        txn.target.previousSibling.classList.remove('left')
      }

      if (Math.abs(e.deltaX) >= 60) {
        txn.target.classList.add('active')
        txn.target.previousSibling.classList.add('active')
      } else {
        txn.target.classList.remove('active')
        txn.target.previousSibling.classList.remove('active')
      }
    },
    panEnd(e, key, txn) {
      if (
        !txn.target
        || this.panDisabled
        || this.headerLoading
        || this.ptrScroll > 0
      ) return this.panDisabled = false
      
      if (Math.abs(e.deltaX) >= 60) {
        if (e.deltaX >= 0) {
          setTimeout(() => this._txnSign(key, txn), 150)
        } else {
          setTimeout(() => this._txnReject(txn), 150)
        }

        setTimeout(() => this.panReset(e, txn), 150)
      } 
      
      else
        this.panReset(e, txn)
    },
    panReset(e, txn) {
      txn.target.classList.remove('active')
      txn.target.previousSibling.classList.remove('active')

      Velocity(txn.target, {
        right: e.deltaX
      }, {
        complete: () => {
          this.panMoving = false

          txn.target.style.transform = null
          txn.target.style.right = null
          txn.target.classList.remove('left', 'right')
          txn.target.previousSibling.classList.remove('left', 'right')
        }
      })
    },
    panNoop: () => _.noop()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

.app {
  padding: 0;
  overflow: hidden;

  &.mobile {

    main {

      &:after { // Forces an overscroll for when keys are empty or limited
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 1px;
        height: 1px;
      }
    }
  }
  &.loading {

    header {
      background-color: $ui-1;
      box-shadow: none;
      height: 100vh;

      .settings,
      .add {
        display: none;
      }
      .logo {
        height: 60px;
      }
    }
  }
}

header {
  margin: 0;
  z-index: 2;
  height: 60px;
  background-color: $ui-2;
  box-shadow: 0 1px 0 0 $ui-3;
  transition: height 350ms, background-color 350ms;
  justify-content: space-between;
  padding: 0 15px;

  .logo {
    height: 40px;
    margin: 0;
    transition: height 350ms;
    
    path {
      opacity: 1;
      transform: translateZ(0) scale(1);
      transition: opacity 166ms linear, transform 266ms ease-in-out;
      transform-origin: center;
      backface-visibility: hidden;
      perspective: 1000;

      &.hide {
        opacity: 0;
        transform: translateZ(0) scale(0.8);
      }
    }
  }
}

.ptr {
  user-select: none;
  position: absolute;
  top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 0;
  overflow: hidden;
  height: 0;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;

  i {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

main {
  padding: 0;
  flex-shrink: 1;
  height: 100%;
  position: relative;
}

.keys {
  width: 100%;

  &:first-of-type .key:first-of-type .key-describe {
    box-shadow: inset 0 1px 0 0 $ui-3;
  }
}
.key {

  &.empty {
    padding: 15px 20px;
    text-align: center;
    border-top: 1px solid $ui-3;
    background-color: $ui-1;

    &:last-of-type {
      border-bottom: 1px solid $ui-3;
    }
  }
}
.key-describe {
  background-color: $ui-2;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;

  img {
    width: 25px;
    height: 25px;
  }
  p {
    font-size: 12px;
    text-align: left;
    line-height: 1;
    margin-right: auto;
    margin-left: 5px;
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 5px;

    &.green {
      color: $green;
    }
    &.red {
      color: $red;
    }
  }
}
hr {
  height: 1px;
  background-color: $ui-3;
  border: none;
  transition: background-color 150ms;

  &:last-of-type {
    z-index: 1;
  }
  &.sticky {
    top: 25px;
    position: sticky;
    margin-bottom: -1px;
    z-index: -1;
  }
  &.left.active {
    background-color: $green;
  }
  &.right.active {
    background-color: $red;
  }
}

.txns {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.txn {
  display: flex;
  position: relative; // needed for the animations
  backface-visibility: hidden;
  perspective: 1000;
    
  &.left.active {
    
    + hr {
      background-color: $green;
    }
  }
  &.right.active {

    + hr {
      background-color: $red;
    }
  }
  &.left {
    flex-direction: row-reverse;

    .txn-action {
      justify-content: flex-end;
      background-image: url('../../assets/img/sign.svg');
      background-position: calc(100% - 10px) center;
    }

    &.active .txn-action {
      background-color: $green;
    }
  }
  &.right {
    flex-direction: row;

    .txn-action {
      justify-content: flex-start;
      background-image: url('../../assets/img/reject.svg');
      background-position: 10px center;
    }

    &.active .txn-action {
      background-color: $red;
    }
  }
}
.txn-content {
  width: 100%;
  padding: 15px;
  flex-shrink: 0;
}
.txn-describe {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    text-align: left;
    font-size: 20px;
    line-height: 1;
    max-width: inherit;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      &.signed {

        path {
          fill: $green;
        }
      }
      &.rejected {
        padding: 1px;

        path {
          fill: $red;
        }
      }
    }
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
  }
}
time {
  text-transform: capitalize;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  opacity: 0.5;

  img {
    width: auto;
    margin-right: 5px;
  }
}
.ops {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.op {
  font-size: 14px;
  margin-top: 5px;

  &:first-of-type {
    margin-top: 0;
  }
}
.txn-action {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transition: background-color 150ms;
  background-color: $ui-3;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  width: 100vw;
}
</style>