import { axios, cancel, cancelToken } from '../assets/js/axios'
import { Plugins } from '@capacitor/core'
import { txnSign } from '../assets/js/utils'

const { Device } = Plugins

export default {
  keyList({state, commit}) {
    if (cancel['keyList'])
      cancel['keyList']()

    return axios.get('/key/list', {
      cancelToken: new cancelToken((c) => cancel['keyList'] = c),
    })
    .then(({data: {keys}}) => commit('setKeys', keys))
    .catch((err) => {
      if (!state.keys) // Set keys to zero if this is the first load and it fails
        commit('setKeys')

      console.error(err)
    })
  },
  keyClaim({}, key) {
    return axios.post('/key/claim', key)
    .then(({data}) => data)
  },

  txnList({state, commit}) {
    if (cancel['txnList'])
      cancel['txnList']()

    return axios.get('/txn/list', {
      cancelToken: new cancelToken((c) => cancel['txnList'] = c),
    })
    .then(({data: {txns}}) => commit('setTxns', txns))
    .catch((err) => {
      if (!state.txns) // Set txns to zero if this is the first load and it fails
        commit('setTxns')

      console.error(err)
    })
  },
  txnSign({ state }, {xdr, cipher}) {
    try {
      const txn = txnSign(state.password, cipher, xdr)
      
      return axios
      .post('/txn/sign', { xdr: txn.toXDR() })
      .catch(() => console.error(err))
    } catch(err) {
      alert('Failed to accept transaction')
    }
  },
  txnReject({}, txn) {
    return axios.delete('/txn/reject', { data: { txn }})
    .catch((err) => console.error(err))
  },

  async getInfo({commit}) {
    commit('setInfo', await Device.getInfo())
  },
  async refetchData({dispatch}) {
    await dispatch('keyList')
    await dispatch('txnList')
  },
  signOut({state, dispatch}) {
    if (
      state.user 
      && state.fcm
    ) state.fcm.unsubscribeFrom({topic: state.user.publicKey()})

    if (state.channel)
      state.channel.unbind_all()

    sessionStorage.removeItem('StellarAuth.Password')
    localStorage.removeItem('StellarAuth.Cipher')

    dispatch('setPassword')
    dispatch('setCipher')
    dispatch('setUser')

    location.reload()
  },
}