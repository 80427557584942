<template>
  <div class="app">
    <header>
      <button class="back" @click="$emit('setAccountTemplate', 'view-account-qr-code')"><img src="../../assets/img/back.svg"></button>
      <img class="logo" src="../../assets/img/stellarauth.svg">
      <h1>StellarAuth</h1>
    </header>

    <main>
      <h2>Congratulations!</h2>
      <p>Account is now setup! All that’s left is to start adding keys, and enjoying the most secure accounts you’ve ever seen!</p>

      <div class="actions">
        <button class="add-key" @click="$emit('setAccountTemplate', 'scan-key-qr-code')">Add a Key</button>
        <button class="go-home" @click="$emit('setAccountTemplate', 'main')">Take Me to My Account</button>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {

    }
  },
  mounted() {
    this.refetchData([

    ])
  },
  computed: {
    
  },
  methods: {
    ...mapActions([
      'refetchData'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

.actions {
  margin-top: 60px;
  
  .add-key {
    @extend %button-primary;
    margin-bottom: 20px;
  }
  .go-home {
    @extend %button-outline;
  }
}
</style>