<template>
  <div class="app" :class="{'qr-status-showing': qr_status.showing}">
    <header>
      <button class="back" @click="$emit('setLaunchTemplate', 'main')"><img src="../../assets/img/back.svg"></button>
      <img class="logo" src="../../assets/img/stellarauth.svg">
      <h1>StellarAuth</h1>
    </header>
    
    <main>
      <h2>Scan, Import or Paste an Account QR Code</h2>

      <form @submit.prevent="next" class="actions">
        <button type="button" class="scan" @click="scanQrCode" v-if="info && (info.platform === 'ios' || info.platform ==='android')"><img src="../../assets/img/scan.svg"> <span>Scan</span></button>
        <button type="button" class="import">
          <img src="../../assets/img/import.svg"> 
          <span>Import</span>
          <input type="file" @change="fileChanged($event)" accept="image/png" ref="file">
        </button>

        <textarea class="paste" placeholder="Paste" v-model="cipher"></textarea>

        <button type="submit" class="confirm">Next</button>
      </form>
    </main>

    <button class="close" @click="closeQr">
      <span></span>
      <span></span>
    </button>
  </div>
</template>

<script>
import qrCodeParser from 'qrcode-parser'
import { mapState, mapMutations } from 'vuex'
import { QRScanner } from '@ionic-native/qr-scanner'

export default {
  data() {
    return {
      qr_status: {}
    }
  },
  created() {

  },
  mounted() {

  },
  destroyed() {
    
  },
  computed: {
    ...mapState([
      'info'
    ]),
    cipher: {
      get() {
        return this.$store.state.cipher
      },
      set(cipher) {
        this.setCipher(cipher)
      }
    },
  },
  watch: {
    
  },
  methods: {
    ...mapMutations([
      'setCipher'
    ]),
    fileChanged(e) {
      qrCodeParser(e.target.files[0])
      .then(({data}) => this.setCipher(data))
    },
    closeQr() {
      this.qr_status.showing = false

      if (
        document.querySelector('body').hasAttribute('style')
        || document.querySelector('html').hasAttribute('style')
      ) {
        document.querySelector('body').removeAttribute('style')
        document.querySelector('html').removeAttribute('style')
      }
    },
    async scanQrCode() {
      if (!this.qr_status.prepared)
        this.qr_status = await QRScanner.prepare()
         
      if (this.qr_status.authorized) {
        QRScanner.show()
        this.qr_status.showing = true

        const scan = QRScanner.scan().subscribe((text) => {
          scan.unsubscribe()
          this.cipher = text
          this.closeQr()
        })
      } else if (this.qr_status.denied) {
        // camera permission was permanently denied
        // you must use QRScanner.openSettings() method to guide the user to the settings page
        // then they can grant the permission from there
      } else {
        // permission was denied, but not permanently. You can ask for permission again at a later time.
      }
    },
    next() {
      if (this.cipher)
        this.$emit('setLaunchTemplate', 'sign-in')
      else
        alert('Scan, import or paste in an account qr code')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

.app {

  &.qr-status-showing {

    header,
    main {
      display: none;
    }
    .close {
      display: block;
    }
  }
}

h2 {
  margin: 0;
  line-height: 1.5;
  max-width: 220px;
}

.actions {
  margin-top: 40px;

  button {
    @extend %button-primary;
    margin-bottom: 30px;

    &:last-of-type {
      margin: 0;
    }
  }
  textarea {
    background-color: $ui-9;
    border-radius: 15px;
    height: 150px;
    padding: 15px 30px;
    width: 100%;
    color: $ui-3;
    resize: none;
    margin-bottom: 30px;
  }
  .scan {
    
  }
  .import {
    position: relative;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
    }
  }
  .confirm {

  }
}

.close {
  @extend %button-outline;
  width: 55px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  display: none;

  span {
    width: 20px;
    height: 2px;
    background-color: $ui-9;
    position: absolute;
    top: 50%;
    left: 50%; 
  }
  span:first-of-type {
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
  }
  span:last-of-type {
    transform: rotate(-45deg) translate(-50%, -50%);
    transform-origin: top left;
  }
}
</style>