<template>
  <component
    v-if="user"
    :is="accountTemplate" 
    @setAccountTemplate="setAccountTemplate" 
    @setLaunchTemplate="setLaunchTemplate" 
  ></component>

  <component 
    v-else
    :is="launchTemplate" 
    @setAccountTemplate="setAccountTemplate" 
    @setLaunchTemplate="setLaunchTemplate" 
  ></component>
</template>

<script>
import _ from 'lodash'

import { mapState, mapActions, mapMutations } from 'vuex'
import { Keypair } from 'stellar-sdk'
import { Plugins } from '@capacitor/core'
import sjcl from 'sjcl'
import pusher from './assets/js/pusher'
import config from './assets/js/config'

import $launchMain from './components/launch/main'
import $launchSignUp from './components/launch/sign-up'
import $launchSignIn from './components/launch/sign-in'
import $launchScanAccountQrCode from './components/launch/scan-account-qr-code'

import $accountMain from './components/account/main'
import $accountSettings from './components/account/settings'
import $accountFresh from './components/account/fresh'
import $accountViewAccountQrCode from './components/account/view-account-qr-code'
import $accountScanKeyQrCode from './components/account/scan-key-qr-code'

const { PushNotifications, SplashScreen } = Plugins

export default {
  data() {
    return {
      launchTemplate: $launchMain,
      accountTemplate: $accountMain,
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    if (this.cipher) {
      if (this.password) {
        try {
          console.log('auto signed in')

          const secret = sjcl.decrypt(
            this.password,
            atob(this.cipher)
          )

          const keypair = Keypair.fromSecret(secret)
          
          this.setUser(keypair)
        } catch(err) {
          console.log('auto sign in failed')
        } 
      }

      else {
        console.log('redirect to password')
        this.launchTemplate = $launchSignIn
      }
    }
  },
  computed: {
    ...mapState([
      'info',
      'user',
      'cipher',
      'password',
      'fcm',
      'channel'
    ])
  },
  components: {
    'launch-main': $launchMain,
    'launch-sign-up': $launchSignUp,
    'launch-sign-in': $launchSignIn,
    'launch-scan-account-qr-code': $launchScanAccountQrCode,

    'account-main': $accountMain,
    'account-settings': $accountSettings,
    'account-fresh': $accountFresh,
    'account-view-account-qr-code': $accountViewAccountQrCode,
    'account-scan-key-qr-code': $accountScanKeyQrCode,
  },
  watch: {
    async info() {
      if (
        this.info.platform === 'ios'
        || this.info.platform === 'android'
      ) {
        document.querySelector('body').classList.add('touchscroll')
        SplashScreen.hide()

        PushNotifications.register()
        PushNotifications.addListener('registration', (token) => {
          console.log('Push registration success, token: ' + token.value)
        })
        PushNotifications.addListener('registrationError', (error) => {
          console.error('Error on registration: ' + JSON.stringify(error))
        })
        PushNotifications.addListener('pushNotificationReceived', (notification) => {
          console.log('Push received: ' + JSON.stringify(notification))
        })
        PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
          console.log('Push action performed: ' + JSON.stringify(notification))
        })

        // if (config.isDeploy) {
        //   const update = await Deploy.checkForUpdate()

        //   if (update.available) {
        //     await Deploy.downloadUpdate()
        //     await Deploy.extractUpdate()
        //     await Deploy.reloadApp()
        //   } 
        // }
      }
      
      else {
        document.querySelector('body').classList.remove('touchscroll')
      }
    },
    user() {
      if (this.user) {
        if (!this.channel)
          this.setChannel(pusher.subscribe(this.user.publicKey()))

        this.channel.bind_global((event, data) => {
          if (event.indexOf('pusher') === -1)
            this.refetchData()
        })

        if (
          this.info
          && (
            this.info.platform === 'ios'
            || this.info.platform === 'android'
          )
        ) {
          PushNotifications.register()
          .then(() => this.fcm.subscribeTo({topic: this.user.publicKey()}))
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setUser',
      'setChannel'
    ]),
    ...mapActions([
      'refetchData',
      'getInfo'
    ]),
    setLaunchTemplate(name) {
      this.launchTemplate = `launch-${name}`
    },
    setAccountTemplate(name) {
      this.accountTemplate = `account-${name}`
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/vamp';

.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  overflow: auto;

  > * {
    flex-shrink: 0;
  }
}
.app::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  margin: 40px 0;
  width: 100%;

  .logo {
    height: 45px;
    width: auto;
    margin: 0 10px 0 auto;
  }
  h1 {
    font-size: 32px;
    margin-right: auto;
  }
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 40px;

  > * {
    flex-shrink: 0;
  }

  h2,
  p {
    text-align: center;
  }

  h2 {
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    max-width: 300px;
    line-height: 1.5;
  }
}
main::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
</style>