import sjcl from 'sjcl'
import { Transaction, Networks, Keypair } from 'stellar-sdk'
import config from './config'

export function txnSign(password, cipher, xdr) {
  const keyKeypair = Keypair.fromSecret(
    sjcl.decrypt(
      password,
      atob(cipher)
    )
  )

  const txn = new Transaction(xdr, Networks[config.network])
      
  txn.sign(keyKeypair)

  return txn
}