<template>
  <div class="app">
    <header>
      <button class="back" @click="$emit('setLaunchTemplate', 'scan-account-qr-code')"><img src="../../assets/img/back.svg"></button>
      <img class="logo" src="../../assets/img/stellarauth.svg">
      <h1>StellarAuth</h1>
    </header>
    
    <main>
      <h2>Enter Account QR Code Password</h2>

      <form @submit.prevent="signIn">
        <input type="password" placeholder="Password" v-model="password">
        <button type="submit" class="confirm">Confirm</button>
      </form>
    </main>
  </div>
</template>

<script>
import { Keypair } from 'stellar-sdk'
import sjcl from 'sjcl'
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      password: null
    }
  },
  computed: {
    ...mapState([
      'info',
      'cipher'
    ])
  },
  methods: {
    ...mapMutations([
      'setUser',
      'setPassword',
      'setCipher'
    ]),
    async signIn() {
      try {
        const secret = sjcl.decrypt(
          this.password,
          atob(this.cipher)
        )

        const keypair = Keypair.fromSecret(secret)

        this.setPassword(this.password)
        this.setCipher(this.cipher)
        this.setUser(keypair)
      } catch(err) {
        alert(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
input {
  background-color: $ui-9;
  border-radius: 15px;
  height: 50px;
  padding: 0 30px;
  width: 100%;
  color: $ui-3;
}
.confirm {
  @extend %button-primary;
  margin-top: 30px;
}
</style>