import { FCM } from 'capacitor-fcm'

export default {
  keys: null,
  txns: null,
  info: null,
  user: null,
  password: sessionStorage.getItem('StellarAuth.Password'),
  cipher: localStorage.getItem('StellarAuth.Cipher'),
  channel: null,
  fcm: new FCM()
}