import Vue from 'vue'
import { VueHammer } from 'vue2-hammer'

import './assets/scss/style.scss'
import store from './store/index'
import App from './App'

VueHammer.config.pan = {
  threshold: 0,
  direction: 6
}

Vue.use(VueHammer)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')