<template>
  <div class="app">
    <header>
      <button class="back" @click="$emit('setLaunchTemplate', 'main')"><img src="../../assets/img/back.svg"></button>
      <img class="logo" src="../../assets/img/stellarauth.svg">
      <h1>StellarAuth</h1>
    </header>

    <main>
      <h2>Enter a Secure Master Password</h2>
      <p>Make sure and keep track of this! If you lose this, you’ll lose access to any keys you’ve verified.</p>

      <form @submit.prevent="signUp">
        <input type="password" placeholder="Password" v-model="password">
        <input type="password" placeholder="Verify Password" v-model="password_again">

        <button type="submit" class="confirm">Confirm</button>
      </form>
    </main>
  </div>
</template>

<script>
import sjcl from 'sjcl'
import { Keypair } from 'stellar-sdk'
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      password: null,
      password_again: null
    }
  },
  computed: {
    ...mapState([
      'info'
    ])
  },
  methods: {
    ...mapMutations([
      'setUser',
      'setPassword',
      'setCipher'
    ]),
    async signUp() {
      if (
        this.password
        && this.password_again
        && this.password === this.password_again
      ) {
        const keypair = Keypair.random()
        const cipher = btoa(sjcl.encrypt(this.password, keypair.secret()))

        this.setPassword(this.password)
        this.setCipher(cipher)
        this.setUser(keypair)

        this.$emit('setAccountTemplate', 'view-account-qr-code')
      }

      else
        alert('Password is empty or doesn\'t match')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/vamp';

form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
input {
  background-color: $ui-9;
  border-radius: 15px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 30px;
  width: 100%;
  color: $ui-3;

  &:last-of-type {
    margin: 0;
  }
}
.confirm {
  @extend %button-primary;
  margin-top: 40px;
}
</style>