import Axios from 'axios'
import moment from 'moment'
import { TransactionBuilder, Account, Networks } from 'stellar-sdk'
import store from '../../store/index'
import config from './config'

const _axios = Axios.create({
  baseURL: config.authUrl
})

_axios.interceptors.request.use((request) => {
  if (store.state.user) {
    const date = moment()
    const txn = new TransactionBuilder(
      new Account(store.state.user.publicKey(), '0'),
      { 
        fee: '0',
        timebounds: {
          minTime: date.format('X'),
          maxTime: date.add(30, 'seconds').format('X')
        },
        networkPassphrase: Networks[config.network]
      }
    )
    .build()

    txn.sign(store.state.user)

    request.headers.Authorization = `Bearer ${txn.toXDR()}`
  }

  return request
})

export const axios = _axios
export const cancelToken = Axios.CancelToken
export const cancel = {}
