<template>
  <svg width="43" height="40" viewBox="0 0 43 40" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'loader'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g class="group" fill="#30F271" fill-rule="evenodd"><path class="top" d="M18.875 7.151L10.893 9.5l7.982 2.349L21.5 18.99l2.625-7.142L32.107 9.5l-7.982-2.349L21.5.01z"/><path class="right" d="M29.875 17.151L21.893 19.5l7.982 2.349L32.5 28.99l2.625-7.142 7.982-2.349-7.982-2.349L32.5 10.01z"/><path class="bottom" d="M18.875 28.151L10.893 30.5l7.982 2.349L21.5 39.99l2.625-7.142 7.982-2.349-7.982-2.349L21.5 21.01z"/><path class="left" d="M7.875 18.151L-.107 20.5l7.982 2.349L10.5 29.99l2.625-7.142 7.982-2.349-7.982-2.349L10.5 11.01z"/></g></svg>
</template>

<script>
export default {
  
}
</script>

<style lang="scss">
@import '../../assets/scss/vamp';

.loader {

  svg {
    width: 20px;
    height: 20px;
  }
  path {
    transform: translateZ(0) scale(0.8);
    backface-visibility: hidden;
    perspective: 1000;
    transition: opacity $loader-animation-duration linear, transform $loader-animation-duration + 100 ease-in-out;
    transform-origin: center;
    opacity: 0;
  }

  .top,
  .right,
  .bottom,
  .left {
    animation: $loader-animation-duration infinite fade linear;
  }
  .top {
    animation-delay: $loader-animation-duration / 4 * 0;
  }
  .right {
    animation-delay: $loader-animation-duration / 4 * 1;
  }
  .bottom {
    animation-delay: $loader-animation-duration / 4 * 2;
  }
  .left {
    animation-delay: $loader-animation-duration / 4 * 3;
  }

  .group {
    fill: $ui-9;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateZ(0) scale(0.8);
  }

  50% {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }

  100% {
    opacity: 0;
    transform: translateZ(0) scale(0.8);
  }
}
</style>